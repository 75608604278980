import React, { useEffect, useState } from "react";
import styles from "./TransactionHistory.module.scss";
import FInanceWrapper from "../FinanceWrapper/FInanceWrapper";
import Table2 from "../../../common/Components/Table2/Table2";
import { convertISOToCustomFormat, formatDate } from "../../../utils/date";
import DataError from "../../../common/UI/DataError/DataError";
import Loader from "../../../common/UI/Loader/Loader";
import NoDataFound from "../../../common/UI/NoDataFound/NoDataFound";
import { formatCurrency } from "../../../utils/currencyFormatter";
import Button from "../../../common/Components/Button/Button";
import Modal from "../../../common/Components/Modal/Modal";
import { showToast } from "../../../store/toastSlice";
import { useDispatch } from "react-redux";
import { reverseTransaction } from "../../../services/finance";

const TransactionHistory = ({
  data,
  error,
  loading,
  onReload,
}: {
  data: any[];
  loading: boolean;
  error: boolean;
  onReload: () => void;
}) => {
  const dispatch = useDispatch();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [reverting, setReverting] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>({});

  const getStatusClass = (status: "PENDING" | "FAILED" | "SUCCESSFUL") => {
    if (status === "FAILED") {
      return styles.failed;
    }
    if (status === "PENDING") {
      return styles.pending;
    }
    if (status === "SUCCESSFUL") {
      return styles.success;
    }
  };

  const closeRevertPopup = () => {
    setIsDeleteOpen(false);
  };

  const handleRevert = async () => {
    try {
      setReverting(true);
      const response = await reverseTransaction(selectedItem.id);
      if (response.status) {
        onReload();
        closeRevertPopup();
        dispatch(
          showToast({
            message: "Reversed successfully",
            type: "success",
            timeout: 5000,
          })
        );
      }
    } catch (err: any) {
      dispatch(
        showToast({
          message: err.response.data.error || err.message,
          type: "error",
          timeout: 5000,
        })
      );
    } finally {
      setReverting(false);
    }
  };

  return (
    <FInanceWrapper>
      <div className={styles.container}>
        {loading && <Loader />}
        {error && (
          <DataError
            btnProps={{
              onClick: () => {
                onReload();
              },
            }}
            title="Something went wrong"
            btnLabel="Reload Transactions"
          />
        )}
        {!loading && !error && (
          <Table2 isFixed={true}>
            {" "}
            <thead>
              <tr>
                <th>No.</th>
                <th>Name</th>
                <th>Account No.</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Team</th>
                <th>Status</th>
                <th>Revert</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((x: any, i: number) => {
                return (
                  <tr key={x.id}>
                    <td>{i + 1}</td>
                    <td>{x?.to_user_name}</td>
                    <td>{x.to_account}</td>
                    <td>{convertISOToCustomFormat(x.date)}</td>
                    <td title={x.amount}>{formatCurrency(x.amount)}</td>
                    <td>{x.team}</td>
                    <td>
                      <div
                        className={`${styles.status} ${getStatusClass(
                          x.status
                        )} `}
                      >
                        {x.status.charAt(0).toUpperCase() +
                          x.status.slice(1).toLowerCase()}
                      </div>
                    </td>
                    <td>
                      <Button
                        disabled={x.is_reversed}
                        theme="light"
                        type="full"
                        onClick={() => {
                          setSelectedItem(x);
                          setIsDeleteOpen(true);
                        }}
                      >
                        <div className={styles.inner}>{x.is_reversed ? 'Reverted' : 'Revert'}</div>
                      </Button>
                    </td>
                  </tr>
                );
              })}
              {data?.length === 0 && (
                <tr>
                  <td colSpan={3}>
                    <NoDataFound title={`Sorry No Transactions`} />
                  </td>
                </tr>
              )}
            </tbody>
          </Table2>
        )}
      </div>
      <Modal show={isDeleteOpen} closeBtn={true} closeMethod={closeRevertPopup}>
        <div className={styles.modalBody}>
          <div className={styles.popHead}>Revert Transaction</div>
          <div className={styles.description}>
            Are you sure you want to revert the transaction?
          </div>
          <div className={`${styles.btnOuter}`}>
            <Button
              onClick={(e) => {
                if (!reverting) {
                  closeRevertPopup();
                }
              }}
              theme="danger"
            >
              <div className={styles.dangerBtn}>Cancel</div>
            </Button>
            <Button onClick={handleRevert} disabled={reverting}>
              <div className={styles.authBtn}>
                {reverting ? "Reverting..." : "Revert"}
              </div>
            </Button>
          </div>
        </div>
      </Modal>
    </FInanceWrapper>
  );
};

export default TransactionHistory;
