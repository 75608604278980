import React, { useEffect, useState } from "react";
import styles from "./Finance.module.scss";
import PageAnimation from "../../common/Components/PageAnimation/PageAnimation";
import PageWrapper from "../../common/Layout/PageWrapper/PageWrapper";
import SectionOne from "./SectionOne/SectionOne";
import DonutGraph from "./DoughnutGraph/DonutGraph";
import TransactionHistory from "./TransactionHistory/TransactionHistory";
import { getTxnHistory } from "../../services/finance";
import useApi from "../../hooks/useAPI";
import Pagination from "../../common/Components/Pagination/Pagination";

const Finance = () => {
  const { executeApi, loading, data, error } = useApi(getTxnHistory, {});
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [txnData, settxnData] = useState([]);
  // useEffect(() => {
  //   executeApi();
  // }, []);

  useEffect(() => {
    executeApi(`?page=${page}&page_size=${pageSize}`);
  }, [page, pageCount]);

  console.log("dddd");

  useEffect(() => {
    if (data) {
      if (data.data) {
        settxnData(data.data.transactions);
        setPageCount(Math.ceil(data.data.total / pageSize));
      }
    }
  }, [data]);

  return (
    <div className={styles.container}>
      <PageAnimation>
        <PageWrapper>
          <section>
            <SectionOne
              onReload={() => {
                setPageCount(0);
                setPage(1);
              }}
            />
          </section>
          <section>
            <DonutGraph />
          </section>
          <section>
            <TransactionHistory
              onReload={() => {
                setPageCount(0);
                setPage(1);
              }}
              data={txnData}
              loading={loading}
              error={Boolean(error)}
            />
            {txnData && txnData?.length > 0 && (
              <Pagination
                containerClassName="CustomPaginationSimple"
                pageCount={pageCount}
                initialPage={0}
                onPageChange={(e) => {
                  setPage(e.selected + 1);
                }}
              />
            )}
          </section>
        </PageWrapper>
      </PageAnimation>
      {/* <PageWrapper> */}
    </div>
  );
};

export default Finance;
